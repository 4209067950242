.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: Ubuntu, Helvetica, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
}

a {
  display: block;
  text-decoration: none;
}

.white-heading1 {
  margin: 80px 106px 28px 28px;
  padding-right: 12px;
  float: none;
  border-style: solid;
  border-width: 1px;
  border-color: #facdc1;
  font-family: Georgia, Times, 'Times New Roman', serif;
  color: #fff;
  font-size: 36px;
  line-height: 130%;
  text-align: left;
  white-space: normal;
}

.white-heading1.whiteheading361 {
  display: inline-block;
  margin-top: 18px;
  margin-bottom: 0px;
  margin-left: 50px;
  padding-top: 0px;
  padding-right: 0px;
  border: 0px none transparent;
  font-family: Merriweather, serif;
  font-size: 38px;
  font-weight: 300;
}

.white-heading1.whiteheading361 {
  display: inline-block;
  margin-top: 27px;
  margin-bottom: 0px;
  margin-left: 50px;
  padding-top: 0px;
  padding-right: 0px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border: 0px none transparent;
  font-family: Merriweather, serif;
  font-size: 38px;
  font-weight: 300;
}

.white-heading1.whiteheading361 {
  display: inline-block;
  margin-top: 27px;
  margin-bottom: 0px;
  margin-left: 50px;
  padding-top: 0px;
  padding-right: 0px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border: 0px none transparent;
  font-family: Merriweather, serif;
  font-size: 38px;
  font-weight: 300;
}

.talktoaproicon {
  display: inline-block;
  margin-right: auto;
  margin-bottom: 466px;
  margin-left: auto;
  padding: 10px;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.talktoaproicon:hover {
  position: relative;
  padding: 5px;
  opacity: 0.65;
}

.whiteubuntu17 {
  display: inline-block;
  margin-top: 7px;
  margin-right: 63px;
  padding-right: 122px;
  padding-left: 0px;
  border-bottom: 1px solid #fff;
  font-family: Ubuntu, Helvetica, sans-serif;
  color: #fff;
  font-size: 17px;
  line-height: 189%;
  font-style: normal;
  font-weight: 300;
  text-decoration: none;
}

.whitesubhead {
  margin-top: 6px;
  margin-right: 66px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #fff;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

.socialmediaicon {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin: 18px 28px -8px 0px;
  padding-right: 0px;
  padding-left: 0px;
  float: none;
  clear: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  text-decoration: none;
}

.socialmediaicon:hover {
  color: #f1797f;
  text-decoration: none;
}

.socialmediaicon.space {
  margin-top: 34px;
}

.footer2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 578px;
  margin-top: 101px;
  margin-bottom: 0px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-color: #333852;
}

.grid {
  margin-top: 82px;
  -ms-grid-columns: 1fr 1.75fr;
  grid-template-columns: 1fr 1.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.social-icons {
  height: 146px;
  margin-top: -4px;
  margin-left: -6px;
}

.socailmedialink {
  display: inline-block;
  width: 65px;
  height: 65px;
  margin-right: 48px;
  margin-left: 0px;
  padding: 2px;
  text-decoration: none;
}

.socailmedialink:hover {
  opacity: 0.6;
}

.link-block {
  width: 78px;
  height: 104px;
  background-image: url('../images/PeachyBrand.svg');
  background-position: 0px 0px;
  background-size: contain;
  background-repeat: no-repeat;
}

.image-6 {
  display: inline;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  -o-object-fit: contain;
  object-fit: contain;
}

.yelloheader17 {
  color: #facdc1;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0.1em;
}

.questions1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  margin-left: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.questions._2 {
  display: block;
  margin-top: 24px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.questions.nopicl {
  margin-left: 49px;
}

.heading {
  font-size: 29px;
  line-height: 38px;
  font-weight: 300;
}

.section-heading-2 {
  margin-top: 54px;
  font-family: Merriweather, serif;
}

.surveyintro {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.qimage {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: auto;
  height: 360px;
  margin-top: 15px;
  margin-right: 0px;
  padding-right: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: url('../images/91944345_sB.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  -o-object-fit: none;
  object-fit: none;
}

.qimage._2 {
  display: block;
  background-image: url('../images/AFTERTHEWEDDING.png');
}

.qimage._1 {
  background-image: url('../images/105992831_sCROP.jpg');
}

.qimage._3 {
  display: block;
  height: auto;
  max-width: 90px;
  min-height: 90%;
  min-width: 90%;
  margin-left: 28px;
  background-image: url('../images/119291951_s.jpg');
  -o-object-fit: contain;
  object-fit: contain;
}

.qimage._4 {
  display: block;
  height: 300px;
  background-image: url('../images/144065326_m.jpg');
}

.qimage._5 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: -109px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-image: url('../images/119763724_s.jpg');
  opacity: 0.57;
}

.qimage._6 {
  display: block;
  height: 380px;
  background-image: url('../images/93601134_s.jpg');
  background-position: 50% 50%;
  background-size: auto;
}

.qimage._7 {
  display: block;
  background-image: url('../images/o-MORTGAGE-facebook.jpg');
  background-position: 100% 50%;
  background-size: cover;
}

.qimage._8 {
  display: block;
  height: 340px;
  background-image: url('../images/TEALBACKGROUNDSign.jpg');
  background-position: 100% 50%;
  background-size: auto;
}

.qimage._9 {
  display: block;
  background-image: url('../images/38859218_s.jpg');
}

.qimage._10 {
  display: block;
  height: 65%;
  background-image: url('../images/30350237_mSAT.jpg');
  background-position: 50% 100%;
  background-size: cover;
}

.qimage._1a {
  background-image: url('../images/105992831_sCROP.jpg');
}

.qimage._2a {
  display: block;
  background-image: url('../images/91944345_sB.png');
}

.qimage._3a {
  display: block;
  height: auto;
  max-width: 90px;
  min-height: 90%;
  min-width: 90%;
  margin-left: 28px;
  background-image: url('../images/99319568_s.jpg');
  background-size: cover;
  -o-object-fit: contain;
  object-fit: contain;
}

.qimage._4a {
  display: block;
  height: 300px;
  background-image: url('../images/ServingPapers.jpg');
}

.qimage._6a {
  display: block;
  height: 380px;
  background-image: url('../images/help.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.qimage._7a {
  display: block;
  background-image: url('../images/64458707_m.jpg');
  background-position: 100% 50%;
  background-size: cover;
}

.qimage._8a {
  display: block;
  height: 340px;
  background-image: url('../images/TEALBACKGROUNDSign.jpg');
  background-position: 100% 50%;
  background-size: auto;
}

.qimage._9a {
  display: block;
  background-image: url('../images/Him.png');
}

.qimage._10a {
  display: block;
  height: 65%;
  background-image: url('../images/Quotes2x.png');
  background-position: 50% 100%;
  background-size: contain;
}

.qimage._2a {
  background-image: url('../images/PointingFinger2x.png');
}

.qimage._2a {
  display: block;
  background-image: url('../images/AFTERTHEWEDDING.png');
}

.qimage._1b {
  height: 280px;
  background-image: url('../images/PointingFinger2x.png');
}

.qimage._2c {
  display: block;
  background-image: url('../images/38438846_s.jpg');
}

.qimage._3c {
  display: block;
  height: auto;
  max-width: 90px;
  min-height: 90%;
  min-width: 90%;
  margin-left: 28px;
  background-image: url('../images/politics_main_1440x800.jpg');
  -o-object-fit: contain;
  object-fit: contain;
}

.qimage._4c {
  display: block;
  height: 300px;
  background-image: url('../images/Religious_Icons2x.png');
  background-size: contain;
}

.qimage._6c {
  display: block;
  height: 380px;
  background-image: url('../images/48500096_s.jpg');
  background-position: 50% 50%;
  background-size: contain;
}

.qimage._7c {
  display: block;
  background-image: url('../images/35407525_m.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.qimage._8c {
  display: block;
  height: 340px;
  background-image: url('../images/93049330_m.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.qimage._9c {
  display: block;
  background-image: url('../images/Scothdecanter.jpg');
  background-position: 50% 100%;
}

.qimage._10c {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('../images/BeachHearts.png');
  background-position: 50% 100%;
  background-size: contain;
}

.qimage._8d {
  display: block;
  height: 340px;
  background-image: url('../images/56220870_mpeach.jpg');
  background-position: 50% 50%;
  background-size: contain;
}

.qimage._10d {
  display: block;
  width: 60%;
  height: 100%;
  background-image: url('../images/132668539_s.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.qimage._2a-copy {
  display: block;
  background-image: url('../images/91944345_sB.png');
}

.html-embed {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -o-object-fit: fill;
  object-fit: fill;
}

.surveyembed {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  height: 700px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.peachylifebrand-2 {
  position: relative;
  left: -295px;
  top: 191px;
  right: 0%;
  bottom: 64px;
  display: block;
  width: 208px;
  height: 75px;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-image: url('../images/PeachyLifeNameWhite.svg');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: scroll;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.peachylifebrand-2:hover {
  opacity: 0.62;
}

.talktopro-2 {
  position: relative;
  left: 0px;
  top: 11%;
  right: 0%;
  bottom: auto;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 200px;
  margin-top: 0px;
  margin-right: 141px;
  margin-left: 0px;
  padding-right: 0px;
  float: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  background-color: hsla(0, 0%, 100%, 0);
}

.talktopro-2:hover {
  opacity: 0.76;
}

.pagenav {
  position: relative;
  left: -318px;
  top: 532px;
  right: 0%;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  margin-left: 0px;
  clear: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}

.marragetesttext-2 {
  position: static;
  margin-top: 34px;
  margin-left: 48px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  font-family: Ubuntu, Helvetica, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2px;
}

.button-2 {
  margin-right: 6px;
  margin-left: 6px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  opacity: 0.64;
}

.button-2:hover {
  opacity: 1;
}

.button-2.w--current {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  clear: none;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  opacity: 0.43;
  text-align: center;
}

.inset-2 {
  display: inline-block;
  width: 300px;
  height: 400px;
  margin: 74px 140px 140px 160px;
  border-style: none;
  border-width: 5px;
  border-color: rgba(0, 0, 0, 0.09);
  border-radius: 0px;
  background-color: transparent;
}

.blue-button-2 {
  position: relative;
  z-index: 5;
  width: 185px;
  margin-top: 27px;
  margin-left: 42px;
  border-radius: 13px;
  background-color: #4593ea;
  font-family: Ubuntu, Helvetica, sans-serif;
  color: #fff;
  font-size: 18px;
  text-align: center;
}

.blue-button-2:hover {
  width: 185px;
  border-radius: 0px;
  background-color: #eb616c;
}

.blue-button-2:active {
  width: 158px;
  border-radius: 17px;
  opacity: 0.6;
}

.blue-button-2.w--current {
  position: relative;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 180px;
  margin-top: 29px;
  margin-left: 46px;
  float: none;
  opacity: 1;
  -webkit-transition: all 275ms ease;
  transition: all 275ms ease;
  color: #fff;
  font-style: normal;
  text-decoration: none;
}

.blue-button-2.w--current:hover {
  color: #fff;
}

.peachylife-peach-2 {
  position: relative;
  width: 200px;
  z-index: 5;
  margin-top: 10px;
  margin-left: -61px;
  background-clip: padding-box;
}

.peachylife-peach-2:hover {
  opacity: 0.61;
}

.text-block-3 {
  position: relative;
  display: inline-block;
  padding: 13px;
  float: none;
  font-family: Ubuntu, Helvetica, sans-serif;
  color: #eb616c;
  font-size: 24px;
  line-height: 112%;
  text-align: center;
  text-decoration: none;
}

.blueinset-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 436px;
  margin-top: -1px;
  float: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: rgba(10, 21, 56, 0.38);
}

.home-head {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100vw;
  max-height: 650px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  /* background-image: url('../images/93049330_m.jpg'); */
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.home-head._2 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: url('../images/119291951_s.jpg');
}

.home-head._2 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: url('../images/CoupleRAIN31963645_CONT.jpg');
}

.home-head.one {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: url('../images/119291951_s.jpg');
}

.home-head._3 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: url('../images/119291951_s.jpg');
}

.home-head._4 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: url('../images/Coupleafloat17814999_xl.jpg');
}

.text-block-4 {
  position: relative;
  display: inline-block;
  padding: 13px;
  float: none;
  font-family: Ubuntu, Helvetica, sans-serif;
  color: #eb616c;
  font-size: 24px;
  line-height: 112%;
  text-align: center;
}

.heading-2 {
  font-size: 29px;
  line-height: 38px;
  font-weight: 300;
  text-align: left;
}

.section-heading-3 {
  margin-top: 54px;
  font-family: Merriweather, serif;
  text-align: left;
}

.socialmediaicon-2 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin: 18px 28px -8px 0px;
  padding-right: 0px;
  padding-left: 0px;
  float: none;
  clear: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  text-decoration: none;
}

.socialmediaicon-2:hover {
  color: #f1797f;
  text-decoration: none;
}

.socialmediaicon-2.space {
  margin-top: 34px;
}

.whiteubuntu17-2 {
  display: inline-block;
  margin-top: 7px;
  margin-right: 63px;
  padding-left: 0px;
  border-bottom: 1px solid #fff;
  font-family: Ubuntu, Helvetica, sans-serif;
  color: #fff;
  font-size: 17px;
  line-height: 189%;
  font-style: normal;
  font-weight: 300;
  text-decoration: none;
  text-align: left;
}

.footer2-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 720px;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-color: #333852;
}

.yelloheader17-2 {
  color: #facdc1;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0.1em;
  text-align: left;
}

.whitesubhead-2 {
  margin-top: 6px;
  margin-right: 66px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #fff;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}

.peachylifebrand {
  position: relative;
  left: -295px;
  top: 191px;
  right: 0%;
  bottom: 64px;
  display: block;
  width: 208px;
  height: 75px;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-image: url('../images/PeachyLifeNameWhite.svg');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: scroll;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.peachylifebrand:hover {
  opacity: 0.62;
}

.blue-button-3 {
  position: relative;
  z-index: 5;
  width: 185px;
  margin-top: 27px;
  margin-left: 42px;
  border-radius: 13px;
  background-color: #4593ea;
  font-family: Ubuntu, Helvetica, sans-serif;
  color: #fff;
  font-size: 18px;
  text-align: center;
}

.blue-button-3:hover {
  width: 185px;
  border-radius: 0px;
  background-color: #eb616c;
}

.blue-button-3:active {
  width: 158px;
  border-radius: 17px;
  opacity: 0.6;
}

.blue-button-3.w--current {
  position: relative;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 180px;
  margin-top: 29px;
  margin-left: 46px;
  float: none;
  opacity: 1;
  -webkit-transition: all 275ms ease;
  transition: all 275ms ease;
  color: #fff;
  font-style: normal;
  text-decoration: none;
}

.blue-button-3.w--current:hover {
  color: #fff;
}

.button-3 {
  margin-right: 6px;
  margin-left: 2px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  opacity: 0.64;
}

.button-3:hover {
  opacity: 1;
}

.button-3.w--current {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 38px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  clear: none;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  opacity: 0.43;
  text-align: center;
}

.blueinset-31 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 436px;
  margin-top: -1px;
  float: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: rgba(10, 21, 56, 0.38);
}

.survey-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.survey-section.one {
  display: block;
  margin-top: 24px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.surveyintro-2 {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.heading-3 {
  margin-top: 63px;
  font-size: 27px;
  line-height: 38px;
  font-weight: 300;
  text-align: left;
}

.section-heading-4 {
  margin-top: 20px;
  font-family: Merriweather, serif;
  text-align: left;
}

.qgrid {
  margin-top: 134px;
  grid-auto-flow: column;
  grid-auto-rows: minmax(auto, 0.5fr);
  -ms-grid-rows: auto minmax(auto, 1fr);
  grid-template-rows: auto minmax(auto, 1fr);
}

.qgrid.backg {
  width: 100vh;
  background-image: url('../images/TEALBACKGROUNDSUPER.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.questionblock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex !important;
  width: 460px;
  height: 50px;
  margin-top: 8px;
  margin-bottom: 25px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #eb616c;
}

.questionblock:hover {
  color: #eb616c;
}

.answertext {
  width: 400px;
  margin-left: 17px;
  color: #333852;
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 400;
  text-decoration: none;
}

/* .checkbox2 {
  width: 36px;
  height: 36px;
  padding: 3px;
  background-image: url('../images/CHECKBOX.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
} */

/* .checkbox2:hover {
  background-image: url('../images/CHECKHeart.svg');
  background-position: 50% 50%;
  background-size: 95% 95px;
} */

.text-block-5 {
  margin-left: 12px;
  font-family: Merriweather, serif;
  color: #eb616c;
  font-size: 33px;
  line-height: 1.3em;
  font-weight: 300;
  text-indent: -56px;
}

.end1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* width: 100vw; */
  height: 45vw;
  margin-top: 150px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  background-color: #eb616c;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 65vw !important;
  height: 67vh !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  background-color: hsla(0, 0%, 100%, 0.77);
}

.div-block-6.a {
  width: 55vw !important;
  height: 50vh !important;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: hsla(0, 0%, 100%, 0);
}

.heading-4 {
  margin-top: 19px;
  margin-bottom: 0px;
  font-family: Merriweather, serif;
  color: #333852;
  font-size: 30px;
  line-height: 1.2em;
  font-weight: 300;
  text-align: left;
}

.text-span {
  color: #eb616c;
  font-size: 23px;
}

.div-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  height: 50px;
  min-height: 50px;
  margin-top: 23px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  cursor: text;
}

.text-block-6 {
  width: 70%;
  margin-left: 18px;
  font-size: 16px;
}

.text-block-6:hover {
  color: rgba(51, 51, 51, 0.19);
}

.button-4 {
  width: 125px;
  height: 36px;
  margin-right: 0px;
  margin-left: -30px;
  padding-top: 8px;
  border-radius: 11px;
  font-size: 16px;
  text-align: center;
}

.button-4.w--current {
  margin-left: -27px;
}

.image-7 {
  max-width: 25%;
  margin-top: 26px;
  margin-left: 0px;
}

.smallpinktext {
  margin-top: 25px;
  margin-left: 3px;
  font-family: Merriweather, serif;
  color: #eb616c;
  font-size: 16px;
  line-height: 1.2em;
  font-weight: 300;
}

.smallpinktext:hover {
  color: #4593ea;
}

.text-span-2 {
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  font-size: 27px;
}

.answertext-2 {
  width: 400px;
  margin-left: 17px;
  color: #333852;
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 400;
  text-decoration: none;
}

.text-block-7 {
  margin-left: 12px;
  font-family: Merriweather, serif;
  color: #eb616c;
  font-size: 30px;
  line-height: 1.3em;
  font-weight: 300;
  text-indent: 0px;
  text-align: left;
}

.questionblock-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 460px;
  height: 50px;
  margin-top: 12px;
  margin-bottom: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #eb616c;
}

.questionblock-2:hover {
  color: #eb616c;
}

.questionblock-2.bigtext {
  margin-bottom: 14px;
}

.peachylogoinline {
  margin-top: 129px;
  margin-left: 382px;
}

.div-block-8 {
  width: 100%;
}

.progress-bar {
  position: fixed;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 9999;
  display: inline-block;
  overflow: visible;
  width: 100vw;
  height: 12px;
  margin-top: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#4593ea), to(#4593ea));
  background-image: linear-gradient(180deg, #4593ea, #4593ea);
  opacity: 1;
  -webkit-perspective-origin: 0% 50%;
  perspective-origin: 0% 50%;
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

@media screen and (max-width: 991px) {
  .end1{
    height: 60vw !important;
    width: 100vw !important;
  }
  /* .white-heading1.whiteheading361 {
    margin-top: 59px;
    margin-left: 60px;
  } */
  .whiteubuntu17 {
    position: relative;
    display: inline-block;
    width: 86%;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding: 18px 69px 69px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 15px;
    text-align: center;
  }
  .whitesubhead {
    position: relative;
    display: inline-block;
    width: 67%;
    margin-top: 3px;
    margin-right: 0px;
    margin-bottom: 4px;
    padding: 12px 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    line-height: 35px;
    text-align: center;
  }
  .socialmediaicon {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    text-align: center;
  }
  .socialmediaicon.space {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer2 {
    height: auto;
  }
  .footercontainter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .grid {
    grid-auto-flow: row dense;
    -ms-grid-columns: 1.75fr;
    grid-template-columns: 1.75fr;
  }
  .div-block-3 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .social-icons {
    height: auto;
    margin-top: 7px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .link-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 19px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .yelloheader17 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    letter-spacing: 0.2em;
  }
  .div-block-5 {
    text-align: center;
  }
  .questions1 {
    margin-top: 0px;
    margin-left: 0px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .questions._2 {
    width: 90%;
  }
  .heading {
    margin-right: 30px;
    margin-left: 53px;
    color: #333852;
  }
  .section-heading-2 {
    margin-left: 54px;
    color: #333852;
    font-size: 32px;
  }
  .qimage {
    height: 300px;
    width: auto;
  }
  .qimage._4 {
    margin-top: 0px;
  }
  .qimage._5 {
    margin-left: -202px;
  }
  .qimage._2a {
    height: 250px;
  }
  .qimage._1b {
    height: 250px;
  }
  .qimage._8d {
    margin-top: 0px;
  }
  .html-embed {
    margin-right: 85px;
    margin-left: 94px;
  }
  .peachylifebrand-2 {
    left: -125px;
    right: 0px;
    bottom: 6%;
    display: block;
    margin-top: 0px;
    margin-left: 0px;
  }
  .talktopro-2 {
    left: auto;
    top: 13%;
    right: 12%;
    bottom: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0px;
    padding-right: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .pagenav {
    left: -158px;
    right: -4%;
    bottom: 30%;
    margin-bottom: 0px;
    margin-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .marragetesttext-2 {
    margin-top: -47px;
    margin-left: 59px;
  }
  .inset-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 66px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .blue-button-2 {
    margin-left: 58px;
  }
  .peachylife-peach-2 {
    /* margin-top: 36px;
    margin-left: -28px; */

    margin-top: 15px;
    margin-bottom: 50px;
    margin-left: -28px;
  }
  .blueinset-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .heading-2 {
    margin-right: 30px;
    margin-left: 53px;
    color: #333852;
  }
  .section-heading-3 {
    margin-left: 54px;
    color: #333852;
    font-size: 32px;
  }
  .socialmediaicon-2 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    text-align: center;
  }
  .socialmediaicon-2.space {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .whiteubuntu17-2 {
    position: relative;
    display: inline-block;
    width: 86%;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding: 18px 69px 69px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 15px;
    text-align: center;
  }
  .footer2-2 {
    height: auto;
  }
  .yelloheader17-2 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    letter-spacing: 0.2em;
  }
  .whitesubhead-2 {
    position: relative;
    display: inline-block;
    width: 67%;
    margin-top: 3px;
    margin-right: 0px;
    margin-bottom: 4px;
    padding: 12px 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    line-height: 35px;
    text-align: center;
  }
  .peachylifebrand {
    left: -125px;
    right: 0px;
    bottom: 6%;
    display: block;
    margin-top: 0px;
    margin-left: 0px;
  }
  .blue-button-3 {
    margin-left: 58px;
  }
  .blueinset-31 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .survey-section {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .survey-section.one {
    width: 90%;
  }
  .heading-3 {
    margin-right: 30px;
    margin-left: 53px;
    color: #333852;
  }
  .section-heading-4 {
    margin-left: 54px;
    color: #333852;
    font-size: 32px;
  }
  .qgrid {
    -ms-grid-columns: 1fr 0.5fr;
    grid-template-columns: 1fr 0.5fr;
  }
  .qgrid.backg {
    background-image: none;
  }
  .questionblock {
    width: 300px;
  }
  .answertext {
    font-size: 18px;
  }
  /* .checkbox2 {
    min-height: 36px;
    min-width: 36px;
  } */
  .text-block-5 {
    margin-left: 40px;
    font-size: 29px;
    text-indent: 0px;
  }
  .heading-4 {
    margin-top: 15px;
    font-size: 26px;
  }
  .div-block-7 {
    width: 90%;
  }
  .button-4 {
    margin-left: -44px;
  }
  .button-4.w--current {
    width: 100px;
    margin-left: -17px;
  }
  .image-7 {
    margin-top: 31px;
    margin-left: 0px;
  }
  .smallpinktext {
    margin-top: 34px;
    margin-left: 0px;
  }
  .answertext-2 {
    font-size: 18px;
  }
  .text-block-7 {
    margin-left: 40px;
    font-size: 26px;
  }
  .questionblock-2 {
    width: 300px;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .questionblock-2.bigtext {
    margin-top: 29px;
    margin-bottom: 29px;
  }
  .peachylogoinline {
    margin-left: 235px;
  }
  .progress-bar {
    box-shadow: none;
  }
}

@media screen and (max-width: 767px) {
  .white-heading1.whiteheading361 {
    margin-top: 31px;
  }
  .white-heading1.whiteheading361 {
    margin-top: 31px;
  }
  .whiteubuntu17 {
    padding-top: 37px;
  }
  .whitesubhead {
    line-height: 37px;
  }
  .questions1 {
    height: auto;
    margin-top: 23px;
    margin-left: 0px;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .heading {
    margin-top: 7px;
    font-size: 20px;
    line-height: 146%;
  }
  .section-heading-2 {
    margin-top: 16px;
  }
  .qimage {
    height: 220px;
    margin-top: 0px;
  }
  .qimage._3 {
    margin-top: -21px;
    margin-left: 24px;
  }
  .qimage._6 {
    margin-top: 0px;
  }
  .qimage._8 {
    margin-top: 0px;
    background-size: cover;
  }
  .qimage._9 {
    margin-top: 0px;
    background-position: 50% 100%;
    background-size: cover;
  }
  .qimage._3a {
    margin-top: -21px;
    margin-left: 24px;
  }
  .qimage._8a {
    margin-top: 0px;
    background-size: cover;
  }
  .qimage._9a {
    background-position: 50% 100%;
    background-size: cover;
  }
  .qimage._3c {
    margin-top: -21px;
    margin-left: 24px;
  }
  .qimage._7c {
    margin-top: 0px;
  }
  .qimage._8c {
    margin-top: 0px;
    background-size: cover;
  }
  .qimage._9c {
    background-position: 50% 100%;
    background-size: cover;
  }
  .qimage._8d {
    margin-top: 0px;
    background-size: cover;
  }
  .qimage._10d {
    width: 160px;
    height: 180px;
    margin-top: 0px;
  }
  .html-embed {
    margin-top: 40px;
    margin-right: 49px;
    margin-left: 83px;
  }
  .peachylifebrand-2 {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 14%;
    display: block;
    width: 210px;
    height: 61px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .talktopro-2 {
    display: none;
    margin-right: 0px;
    margin-left: 0px;
  }
  .pagenav {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 2%;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .marragetesttext-2 {
    margin-top: -35px;
  }
  .inset-2 {
    position: absolute;
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .blue-button-2 {
    margin-top: 21px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .blue-button-2.w--current {
    margin-top: 21px;
  }
  .peachylife-peach-2 {
    /* max-width: 75px; */
    margin-top: 18px;
    /* margin-left: -222px; */
  }
  .blueinset-2 {
    position: absolute;
    left: -12%;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: 363px;
    height: 420px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .home-head {
    min-height: 550px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .home-head._2 {
    max-height: 680px;
    min-height: 680px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .home-head._2 {
    max-height: 680px;
    min-height: 680px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .home-head.one {
    max-height: 680px;
    min-height: 680px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .home-head._3 {
    max-height: 680px;
    min-height: 680px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .home-head._4 {
    max-height: 680px;
    min-height: 680px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .heading-2 {
    margin-top: 7px;
    font-size: 20px;
    line-height: 146%;
  }
  .section-heading-3 {
    margin-top: 16px;
    margin-bottom: 0px;
  }
  .whiteubuntu17-2 {
    padding-top: 37px;
  }
  .whitesubhead-2 {
    line-height: 37px;
  }
  .peachylifebrand {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 9%;
    display: block;
    width: 210px;
    height: 61px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .blue-button-3 {
    margin-top: 21px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .blue-button-3.w--current {
    margin-top: 21px;
  }
  .blueinset-31 {
    position: absolute;
    left: -12%;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: 363px;
    height: 485px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .survey-section {
    height: auto;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .surveyintro-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .heading-3 {
    margin-top: 7px;
    font-size: 20px;
    line-height: 146%;
  }
  .section-heading-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 16px;
    margin-left: 51px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .qgrid {
    margin-top: 136px;
    grid-column-gap: 21px;
  }
  .questionblock {
    margin-top: 1px;
    margin-bottom: 2px;
  }
  .answertext {
    font-size: 16px;
  }
  /* .checkbox2 {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
  } */
  .text-block-5 {
    margin-left: 49px;
    font-size: 24px;
  }
  .div-block-6 {
    width: 85vw !important;
    height: 80vh !important;
  }
  .div-block-6.a {
    width: 72vw !important;
  }
  .heading-4 {
    margin-top: 50px;
    font-size: 23px;
  }
  .div-block-7 {
    min-width: 320px;
    margin-top: 14px;
  }
  .button-4 {
    width: 100px;
    margin-left: -28px;
  }
  .image-7 {
    position: relative;
    margin-top: 17px;
    margin-left: 0px;
  }
  .smallpinktext {
    position: relative;
    margin-top: 8px;
  }
  .text-span-3 {
    color: #eb616c;
    font-size: 15px;
  }
  .answertext-2 {
    font-size: 15px;
  }
  .text-block-7 {
    margin-top: 21px;
    font-size: 24px;
  }
  .questionblock-2 {
    margin-top: 1px;
    margin-bottom: 2px;
  }
  .questionblock-2.bigtext {
    margin-top: 19px;
    margin-bottom: 0px;
  }
  .peachylogoinline {
    margin-top: 129px;
    margin-left: 163px;
  }
  a.talktopro-2.w-inline-block {
    width: 20%;
    margin-bottom: 45px;
    margin-left: 73px;
  }
  .text-block-3 {
    font-size: 18px;
  }
  .end1 {
    height: 100vh !important;
    margin-top: 0px;
  }
}

@media screen and (max-width: 479px) {
  .white-heading1.whiteheading361 {
    margin-left: 41px;
    font-size: 24px;
  }
  .qgrid {
    height: auto !important;
  }
  .white-heading1.whiteheading361 {
    margin-top: 26px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 26px;
    padding-left: 26px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-size: 24px;
    text-align: center;
  }
  .white-heading1.whiteheading361 {
    margin-top: 36px;
    margin-right: 24px;
    margin-left: 24px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-size: 24px;
    text-align: center;
  }
  .whiteubuntu17 {
    padding-top: 16px;
    font-size: 14px;
    line-height: 153%;
  }
  .whitesubhead {
    width: 61%;
    height: 120px;
    line-height: 32px;
  }
  .footer2 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .social-icons {
    width: 188px;
  }
  .socailmedialink {
    width: 53px;
  }
  .yelloheader17 {
    font-size: 13px;
  }
  .link-block-2 {
    width: 24%;
  }
  .questions1 {
    width: 300px;
    height: 280px;
    margin: 0px auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -o-object-fit: none;
    object-fit: none;
  }
  .questions._5 {
    margin-top: 5px;
  }
  .questions.nopic {
    margin-top: -337px;
  }
  .questions.nopicl {
    margin-top: 0px;
    margin-left: 49px;
  }
  .questions.longer {
    height: 228px;
  }
  .heading {
    margin-right: 0px;
    margin-left: 0px;
    font-size: 16px;
    text-align: center;
  }
  .section-heading-2 {
    margin-top: 4px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-size: 20px;
    text-align: center;
  }
  .surveyintro {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .qimage {
    height: 220px;
    margin-top: 0px;
  }
  .qimage._2 {
    width: 304px;
    height: 180px;
  }
  .qimage._1 {
    height: 180px;
  }
  .qimage._3 {
    max-width: none;
    min-height: 180px;
    min-width: auto;
    margin-top: 0px;
    margin-left: 10px;
  }
  .qimage._4 {
    height: 180px;
    margin-top: 0px;
  }
  .qimage._5 {
    display: block;
    height: 160px;
    margin-top: 1px;
    margin-left: 0px;
  }
  .qimage._6 {
    height: 160px;
    background-position: 50% 50%;
    background-size: contain;
  }
  .qimage._7 {
    height: 180px;
    background-size: contain;
  }
  .qimage._8 {
    height: 180px;
  }
  .qimage._9 {
    height: 180px;
    margin-bottom: 0px;
    background-position: 50% 80%;
  }
  .qimage._10 {
    height: 65%;
  }
  .qimage._1a {
    height: 160px;
    -o-object-fit: none;
    object-fit: none;
  }
  .qimage._2a {
    height: 180px;
  }
  .qimage._3a {
    max-width: 300px;
    min-height: 180px;
    min-width: 300px;
    margin-top: 0px;
    margin-left: 0px;
  }
  .qimage._4a {
    height: 180px;
    margin-top: 20px;
  }
  .qimage._6a {
    height: 140px;
    margin-top: 6px;
    background-position: 50% 50%;
    background-size: contain;
  }
  .qimage._7a {
    position: static;
    height: 160px;
    margin-right: 62px;
    margin-left: 0px;
    background-size: contain;
  }
  .qimage._8a {
    height: 160px;
  }
  .qimage._9a {
    height: 140px;
    margin-bottom: 0px;
    background-position: 50% 50%;
    background-size: cover;
  }
  .qimage._10a {
    height: 129px;
    margin-top: 0px;
  }
  .qimage._2a {
    height: 180px;
    background-image: url('../images/PointingFinger2x.png');
  }
  .qimage._2a {
    height: 180px;
  }
  .qimage._1b {
    height: 180px;
    background-image: url('../images/PointingFinger2x.png');
  }
  .qimage._2c {
    height: 180px;
  }
  .qimage._3c {
    height: 140px;
    max-width: none;
    min-height: 140px;
    margin-top: 0px;
    margin-left: 0px;
  }
  .qimage._4c {
    height: 180px;
    margin-top: 20px;
  }
  .qimage._6c {
    width: 280px;
    height: 200px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    background-position: 50% 50%;
    background-size: contain;
  }
  .qimage._7c {
    height: 180px;
    background-size: contain;
  }
  .qimage._8c {
    height: 180px;
  }
  .qimage._9c {
    width: 300px;
    height: 180px;
    max-height: 180px;
    margin-bottom: 0px;
    background-position: 50% 80%;
    background-size: contain;
  }
  .qimage._10c {
    width: 90%;
    height: 180px;
    margin-right: auto;
    margin-left: auto;
  }
  .qimage._8d {
    height: 180px;
  }
  .qimage._10d {
    width: 300px;
    height: 160px;
    margin-right: auto;
    margin-left: auto;
  }
  .qimage._2a-copy {
    height: 180px;
  }
  .html-embed {
    margin-top: 5px;
    margin-right: 9px;
    margin-left: 7px;
  }
  .surveyembed {
    margin-top: -26px;
  }
  .peachylifebrand-2 {
    left: 0px;
    top: 21px;
    right: 0px;
    bottom: 0px;
    display: block;
    width: 216px;
    height: 56px;
    margin-top: 392px;
    margin-right: auto;
    margin-left: auto;
    clear: none;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .talktopro-2 {
    display: none;
  }
  .pagenav {
    left: 0px;
    right: 0px;
    bottom: 20px;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .marragetesttext-2 {
    position: relative;
    left: auto;
    overflow: hidden;
    margin-top: -30px;
    margin-right: auto;
    margin-left: auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-size: 12px;
  }
  .inset-2 {
    position: relative;
    left: auto;
    top: 0px;
    overflow: visible;
    width: 100%;
    height: 150%;
    min-width: 300px;
    margin: 0px auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .blue-button-2 {
    left: auto;
    width: 155px;
    margin-top: 24px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    text-decoration: none;
  }
  .blue-button-2:hover {
    left: 0px;
    width: 155px;
    margin-left: 0px;
    padding-left: 0px;
  }
  .peachylife-peach-2 {
    left: auto;
    overflow: visible;
    max-width: 23vw;
    /* margin: -26px auto 48px; */
  }
  .blueinset-2 {
    left: 0px;
    top: 75px;
    overflow: visible;
    width: 246px;
    height: 410px;
    margin-top: 24px;
    margin-right: auto;
    margin-left: auto;
    clear: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .home-head {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 477px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .home-head._2 {
    min-width: 100%;
  }
  .home-head._2 {
    min-width: 100%;
  }
  .home-head.one {
    min-width: 100%;
  }
  .home-head._3 {
    min-width: 100%;
  }
  .home-head._4 {
    min-width: 100%;
  }
  .heading-2 {
    margin-right: 0px;
    margin-left: 0px;
    font-size: 16px;
    text-align: center;
  }
  .section-heading-3 {
    margin-top: 4px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-size: 20px;
    text-align: center;
  }
  .whiteubuntu17-2 {
    padding-top: 16px;
    font-size: 14px;
    line-height: 153%;
  }
  .footer2-2 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .yelloheader17-2 {
    font-size: 13px;
  }
  .whitesubhead-2 {
    width: 61%;
    height: 315px;
    line-height: 32px;
  }
  .peachylifebrand {
    left: 0px;
    top: 21px;
    right: 0px;
    bottom: 0px;
    display: block;
    width: 216px;
    height: 56px;
    margin-top: 407px;
    margin-right: auto;
    margin-left: auto;
    clear: none;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .blue-button-3 {
    left: 0px;
    overflow: hidden;
    width: 155px;
    margin-top: 15px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    text-decoration: none;
  }
  .blue-button-3:hover {
    left: 0px;
    width: 155px;
    margin-left: 0px;
    padding-left: 0px;
  }
  .blueinset-31 {
    left: 3px;
    top: 75px;
    overflow: visible;
    width: 246px;
    height: 410px;
    margin-top: 24px;
    margin-right: auto;
    margin-left: auto;
    clear: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .survey-section {
    height: auto;
    margin-bottom: 35px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -o-object-fit: none;
    object-fit: none;
  }
  .surveyintro-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .heading-3 {
    margin-top: 5px;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 16px;
    text-align: center;
  }
  .section-heading-4 {
    margin-top: 7px;
    margin-bottom: 0px;
    margin-left: -12px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
  }
  .qgrid {
    overflow: hidden;
    width: 320px;
    height: 465px;
    margin-top: 58px;
    margin-bottom: 0px;
    -webkit-box-pack: stretch;
    -webkit-justify-content: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 18px;
    -ms-grid-columns: minmax(0px, 0px);
    grid-template-columns: minmax(0px, 0px);
    -ms-grid-rows: -webkit-min-content minmax(-webkit-min-content, -webkit-max-content) minmax(-webkit-min-content, -webkit-max-content);
    -ms-grid-rows: min-content minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-rows: -webkit-min-content minmax(-webkit-min-content, -webkit-max-content) minmax(-webkit-min-content, -webkit-max-content);
    grid-template-rows: min-content minmax(min-content, max-content) minmax(min-content, max-content);
  }
  .qgrid.big {
    width: 320px;
    -ms-grid-columns: minmax(0px, 0px);
    grid-template-columns: minmax(0px, 0px);
  }
  .qgrid.twoc {
    height: 45vh;
    -ms-grid-rows: -webkit-min-content minmax(-webkit-min-content, -webkit-max-content) minmax(0px, 0px);
    -ms-grid-rows: min-content minmax(min-content, max-content) minmax(0px, 0px);
    grid-template-rows: -webkit-min-content minmax(-webkit-min-content, -webkit-max-content) minmax(0px, 0px);
    grid-template-rows: min-content minmax(min-content, max-content) minmax(0px, 0px);
  }
  .qgrid.shorter {
    margin-top: -3px;
  }
  .questionblock {
    width: 260px;
    height: 68px;
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .answertext {
    width: 320px;
    max-width: 260px;
    font-size: 14px;
    line-height: 1.2em;
  }
  .text-block-5 {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 32px;
    font-size: 17px;
    line-height: 1.3em;
    text-indent: 0px;
  }
  .end1 {
    height: 100vh !important;
    margin-top: 0px;
  }
  .div-block-6 {
    width: 90vw !important;
    height: 76vh !important;
  }
  .div-block-6.a {
    width: 80vw !important;
    height: 71vh !important;
    margin-top: 61px;
  }
  .heading-4 {
    margin-top: 10px;
    font-size: 20px;
  }
  .div-block-7 {
    width: 74%;
    min-height: 50px;
    min-width: 250px;
    margin-top: 24px;
  }
  .text-block-6 {
    margin-left: 11px;
  }
  .button-4 {
    width: 120px;
    margin-top: 126px;
    margin-left: -183px;
    padding-right: 5px;
    padding-left: 5px;
  }
  .button-4.w--current {
    width: 120px;
    margin-left: -182px;
  }
  .image-7 {
    margin-top: 78px;
    margin-left: 14px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }
  .smallpinktext {
    margin-top: 27px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 14px;
    line-height: 1.3em;
    white-space: normal;
  }
  .answertext-2 {
    width: 300px;
    height: auto;
    max-width: 280px;
    font-size: 14px;
    line-height: 1.2em;
  }
  .text-block-7 {
    max-width: 300px;
    margin-top: 23px;
    margin-right: auto;
    margin-left: auto;
    font-size: 17px;
    line-height: 1.3em;
    text-indent: 0px;
  }
  .text-block-7.a {
    max-width: 300px;
    margin-top: 0px;
  }
  .questionblock-2 {
    width: 300px;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .questionblock-2.bigtext {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .peachylogoinline {
    margin-top: 17px;
    margin-left: 0px;
  }
  .peachylogoinline.short {
    margin-top: -213px;
  }
  .moblieview {
    display: block;
  }
  .Hamview {
    background-color: #eb616c;
    color: #fff !important;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    text-align: center;
    margin-right: 300px;
  }
  .normalview {
    display: none;
  }
  .HeaderLogo {
    margin-left: 0px !important;
  }
  .ant-layout-header {
    padding: 0px 30px !important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff !important;
  }
  a.talktopro-2.w-inline-block {
    width: 30%;
    margin-bottom: 45px;
    /* margin-left: 38px; */
  }
  .text-block-3 {
    font-size: 18px;
  }
}

#w-node-afdff567636b-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-afdff567637d-9ab4466b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-afdff567636c-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-3fd22b358c6f-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-3fd22b358c70-9ab4466b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-3fd22b358c72-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-f8cf20c53a6e-9ab4466b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f8cf20c53a70-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-f8cf20c53a6d-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-ordinal-group: -9998;
  -webkit-order: -9999;
  -ms-flex-order: -9999;
  order: -9999;
}

#w-node-c01cb90badb3-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c01cb90badb4-9ab4466b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c01cb90badb6-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#Q.w-node-67245dffcd71-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-67245dffcd72-9ab4466b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-67245dffcd74-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-333aa7c698aa-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-333aa7c698ab-9ab4466b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-333aa7c698ad-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-89565eea0ca7-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-89565eea0ca8-9ab4466b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-89565eea0caa-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-d68be0352f21-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-d68be0352f22-9ab4466b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-d68be0352f24-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-fbd5167b2a7b-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-fbd5167b2a7c-9ab4466b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-fbd5167b2a7e-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-9372d13f45f7-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-9372d13f45f8-9ab4466b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-9372d13f45fa-9ab4466b {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-19844c8798ba-4c8798b7 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c40637d158cd-e1b4466e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c40637d158d0-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-c40637d158e3-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c40637d158e4-e1b4466e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c40637d158e6-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-c40637d158f8-e1b4466e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c40637d158fa-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-c40637d1590b-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-ordinal-group: -9998;
  -webkit-order: -9999;
  -ms-flex-order: -9999;
  order: -9999;
}

#w-node-c40637d1590e-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c40637d1590f-e1b4466e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c40637d15911-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#Q.w-node-c40637d15923-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-c40637d15924-e1b4466e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c40637d15926-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-c40637d15939-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c40637d1593a-e1b4466e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c40637d1593c-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-c40637d1594e-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c40637d1594f-e1b4466e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c40637d15951-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-c40637d15963-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c40637d15964-e1b4466e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c40637d15966-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-c40637d15978-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c40637d15979-e1b4466e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c40637d1597b-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-c40637d1598d-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c40637d1598e-e1b4466e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c40637d15990-e1b4466e {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-5ea7a6d5fe0b-8ab4466f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-5ea7a6d5fe0a-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-5ea7a6d5fe0d-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-5ea7a6d5fe1f-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-5ea7a6d5fe20-8ab4466f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-5ea7a6d5fe22-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-5ea7a6d5fe34-8ab4466f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-5ea7a6d5fe36-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-5ea7a6d5fe47-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-ordinal-group: -9998;
  -webkit-order: -9999;
  -ms-flex-order: -9999;
  order: -9999;
}

#w-node-5ea7a6d5fe49-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-5ea7a6d5fe4a-8ab4466f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-5ea7a6d5fe4c-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#Q.w-node-5ea7a6d5fe5e-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-5ea7a6d5fe5f-8ab4466f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-5ea7a6d5fe61-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-5ea7a6d5fe73-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-5ea7a6d5fe74-8ab4466f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-5ea7a6d5fe76-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-5ea7a6d5fe88-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-5ea7a6d5fe89-8ab4466f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-5ea7a6d5fe8b-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-5ea7a6d5fe9d-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-5ea7a6d5fe9e-8ab4466f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-5ea7a6d5fea0-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-5ea7a6d5feb2-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-5ea7a6d5feb3-8ab4466f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-5ea7a6d5feb5-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-5ea7a6d5fec7-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-5ea7a6d5fec8-8ab4466f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-5ea7a6d5feca-8ab4466f {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

@media screen and (max-width: 991px) {
  #w-node-19844c8798ba-4c8798b7 {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-19844c8798d2-4c8798b7 {
    -ms-grid-row-align: start;
    align-self: start;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d1590e-e1b4466e {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
    -ms-grid-column-align: auto;
    justify-self: auto;
  }
}

@media screen and (max-width: 767px) {
  #w-node-afdff567637d-9ab4466b {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-3fd22b358c70-9ab4466b {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-f8cf20c53a6e-9ab4466b {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c01cb90badb4-9ab4466b {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-67245dffcd72-9ab4466b {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-333aa7c698ab-9ab4466b {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-89565eea0ca8-9ab4466b {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-d68be0352f22-9ab4466b {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-fbd5167b2a7c-9ab4466b {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-9372d13f45f8-9ab4466b {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d1590b-e1b4466e {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }
}

@media screen and (max-width: 479px) {
  #w-node-afdff567636b-9ab4466b {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-afdff567637d-9ab4466b {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-afdff567636c-9ab4466b {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-3fd22b358c6f-9ab4466b {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-3fd22b358c72-9ab4466b {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-f8cf20c53a70-9ab4466b {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-f8cf20c53a6d-9ab4466b {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }
  #w-node-c01cb90badb3-9ab4466b {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-c01cb90badb6-9ab4466b {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #Q.w-node-67245dffcd71-9ab4466b {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-67245dffcd74-9ab4466b {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-333aa7c698aa-9ab4466b {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-333aa7c698ad-9ab4466b {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-89565eea0ca7-9ab4466b {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-89565eea0caa-9ab4466b {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-d68be0352f21-9ab4466b {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-d68be0352f24-9ab4466b {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-fbd5167b2a7b-9ab4466b {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-fbd5167b2a7e-9ab4466b {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-9372d13f45f7-9ab4466b {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-9372d13f45fa-9ab4466b {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-19844c8798ba-4c8798b7 {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d158cd-e1b4466e {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d158d0-e1b4466e {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d158e3-e1b4466e {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-c40637d158e4-e1b4466e {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d158e6-e1b4466e {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d158f8-e1b4466e {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d158fa-e1b4466e {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d1590b-e1b4466e {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-c40637d1590e-e1b4466e {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-c40637d1590f-e1b4466e {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d15911-e1b4466e {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row-align: start;
    align-self: start;
  }
  #Q.w-node-c40637d15923-e1b4466e {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-c40637d15926-e1b4466e {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d15939-e1b4466e {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-c40637d1593c-e1b4466e {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d1594e-e1b4466e {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-c40637d15951-e1b4466e {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row-align: start;
    align-self: start;
  }
  #w-node-c40637d15963-e1b4466e {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-c40637d15966-e1b4466e {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d15978-e1b4466e {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-c40637d1597b-e1b4466e {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-c40637d1598d-e1b4466e {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-c40637d15990-e1b4466e {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-5ea7a6d5fe0b-8ab4466f {
    -ms-grid-column-align: center;
    justify-self: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }
  #w-node-5ea7a6d5fe0a-8ab4466f {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-5ea7a6d5fe0d-8ab4466f {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-5ea7a6d5fe1f-8ab4466f {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-5ea7a6d5fe20-8ab4466f {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-5ea7a6d5fe22-8ab4466f {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-5ea7a6d5fe34-8ab4466f {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-5ea7a6d5fe36-8ab4466f {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-5ea7a6d5fe47-8ab4466f {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-5ea7a6d5fe49-8ab4466f {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-5ea7a6d5fe4a-8ab4466f {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-5ea7a6d5fe4c-8ab4466f {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #Q.w-node-5ea7a6d5fe5e-8ab4466f {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-5ea7a6d5fe5f-8ab4466f {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-5ea7a6d5fe61-8ab4466f {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-5ea7a6d5fe73-8ab4466f {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-5ea7a6d5fe74-8ab4466f {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-5ea7a6d5fe76-8ab4466f {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-5ea7a6d5fe88-8ab4466f {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-5ea7a6d5fe89-8ab4466f {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-5ea7a6d5fe8b-8ab4466f {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-5ea7a6d5fe9d-8ab4466f {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-5ea7a6d5fe9e-8ab4466f {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-5ea7a6d5fea0-8ab4466f {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-5ea7a6d5feb2-8ab4466f {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-5ea7a6d5feb3-8ab4466f {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-5ea7a6d5feb5-8ab4466f {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row-align: start;
    align-self: start;
  }
  #w-node-5ea7a6d5fec7-8ab4466f {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-5ea7a6d5fec8-8ab4466f {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-5ea7a6d5feca-8ab4466f {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
}

.button-4 {
  padding-top: 2px !important;
  margin-right: 60px !important
}

/* .end1 {
  height: 35vw;
} */

.div-block-6 {
  width: 55vw;
  height: 65vh;
}

.div-block-6.a {
  width: 45vw;
  height: 50vh;
}

.qs-action:hover {
  background-color: #eb616c;
  color: #fff;
}

.masterbuttoncenter:hover {
  color: white;
}

.gridtext {
  margin-top: 10px;
  text-align: left;
  font-size: 19px;
}

.ReviewRating {
  background: #fff;
  font-size: 23px !important;
}

.ReviewModal .ant-modal-body {
  border-radius: 0px;
}

.ReviewModal .ant-modal-header {
  background-color: #eb616c;
  color: #fff;
}

.ReviewModal .ant-modal-footer {
  background-color: #eb616c;
  color: #fff;
}

.ReviewModal .ant-modal-title {
  color: #fff;
}

.ReviewModal .ant-modal-body {
  background-color: #fff;
}

.ReviewModal .ant-input {
  border: solid 1px #eb616c !important;
}

.ReviewModal .ant-rate-star-first, .ReviewModal .ant-rate-star-second {
  color: black;
}

.contacticon-2 {
  position: static;
  width: 80px;
  height: 80px;
  margin-right: 5px;
  padding: 5px;
  float: left;
  clear: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-style: solid;
  border-width: 5px;
  border-color: #ebeeea;
  border-radius: 58px;
  /* background-image: url('../images/Insurance_1.png'); */
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.contacticon-2:hover {
  border-color: #eb616c;
}

.contacticon-2._3 {
  /* background-image: url('../images/Financial_1.png'); */
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.questionsblock {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80vw;
  height: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  background-color: #eb616c;
}

.div-block-56 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  height: 60%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.ctawhite {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 6%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 26px;
  padding-top: 0px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
}

.text-block-82 {
  color: #eb616c;
  font-size: 80px;
  text-align: center;
}

.div-block-57 {
  width: 88%;
  height: 80%;
  margin-left: 14px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-style: solid;
  border-width: 2px;
  border-color: #9ca09b;
}

.ctaexplaiungrey {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70vw;
  padding-top: 0px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  color: #6e6e70;
  font-size: 17px;
  line-height: 1.5em;
  font-weight: 300;
  text-align: center;
}

.ctablue {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70vw;
  padding-top: 0px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  color: #352f53;
  font-size: 20px;
  line-height: 1.5em;
  font-weight: 400;
  text-align: center;
}

.image-20 {
  display: block;
  margin-top: 14px;
}

.privacy:hover {
  color: #4b4276;
  cursor: pointer;
}

.ant-btn-primary {
  background-color: #eb616c !important;
  border-color: #eb616c !important;
}

.ant-btn-primary:hover {
  background-color: #352f53 !important;
  border-color: #352f53 !important;
}

.text-span-8:hover {
  color: #4b4276;
  cursor: pointer;
}

.text-span-7:hover {
  color: #4b4276;
  cursor: pointer;
}

.menutoptitle-2 {
  width: auto;
  margin-top: 35px;
  margin-bottom: 23px;
  margin-left: 9px;
  padding-right: 0px;
  padding-bottom: 0px;
  font-family: Merriweather, serif;
  font-size: 28px;
  line-height: 23px;
  font-weight: 300;
}

.form-fieldnote-copy {
  display: flex;
  width: 80%;
  height: 160px;
  margin: 5px 0px 10px;
  padding: 10px 10px 10px 15px;
  justify-content: flex-start;
  align-items: flex-start;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  background-color: #fff;
  transition: all 200ms ease;
  color: #283338;
  font-size: 16px;
  text-decoration: none;
}

.logintabfield {
  display: flex;
  width: 80%;
  height: 44px;
  margin: 12px auto 10px 0px;
  padding: 10px 10px 10px 15px;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  background-color: #fff;
  transition: all 200ms ease;
  color: #283338;
  font-size: 16px;
  text-decoration: none;
}

.sendbutton2 {
  position: static;
  left: auto;
  top: auto;
  right: 19%;
  bottom: 8%;
  width: 150px;
  height: 30px;
  margin-top: 10px;
  margin-right: 20px;
  align-self: flex-start;
  background-color: #352f53;
}

.sendbutton2:hover {
  background-color: #eb616c;
  color: #fff;
}

.ReviewModal .ant-rate-star-full .ant-rate-star-second, .ReviewModal .ant-rate-star-half .ant-rate-star-first {
  color: #eb616c!important;
}

.text-block-70 {
  font-size: 14px !important;
  text-align: -webkit-left;
  text-align: left;
}

.video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3 {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
}

/* .video-react .video-react-big-play-button {
  visibility: hidden !important;
} */