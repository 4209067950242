.headerblock {
    position: relative;
    width: 90%;
    max-width: none;
    margin-top: 48px;
    margin-right: 30px;
    margin-left: 9%;
}

.page-section {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    width: 100vw;
    height: auto;
    max-height: none;
    max-width: 1200px;
    min-height: auto;
    margin-left: 11%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    opacity: 1;
}

.article-body {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 75%;
    margin-top: 33px;
    margin-right: 40px;
    margin-left: 5%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.headingtitleblock {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    margin-left: 5%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.div-block-43 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.div-block-60 {
    margin-top: 50px;
    line-height: 25px;
}

.highlightblock {
    position: relative;
    width: 230px;
    height: 170px;
    min-height: 170px;
    min-width: 230px;
    margin-top: 55px;
    margin-right: 38px;
    padding: 42px 23px 28px 32px;
    border-style: none;
    border-width: 3px;
    border-color: rgba(10, 21, 56, 0.38);
    border-radius: 20px;
    background-color: #fff;
    box-shadow: -5px 7px 11px 0 rgb(43 43 43 / 38%);
}

.tabs {
    margin-bottom: 28px;
}

.w-tabs {
    position: relative;
}

.tab-link-tab-1-2.w--current {
    padding-right: 20px;
    padding-left: 20px;
    background-color: #ebeeea;
}

.w-tab-link.w--current {
    background-color: #C8C8C8;
}

.tab-link-tab-1-2 {
    width: 400px;
    background-color: #ebeeea;
}

/* .w-tab-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    padding: 9px 30px;
    text-align: left;
    cursor: pointer;
    color: #222222;
    background-color: #dddddd;
} */

.w-inline-block {
    max-width: 100%;
    display: inline-block;
}

.tabs-menu-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #ebeeea;
}

.w-tab-menu {
    position: relative;
}

.subnavigation {
    width: auto !important;
    margin: 0 0 0 20px !important;
    font-family: Ubuntu, Helvetica, sans-serif;
    color: #4b4276;
    font-size: 16px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
}

.tabs-content-2 {
    padding-left: 20px;
    font-size: 16px;
    margin-top: 20px;
}

.w-tab-link {
    background-color: unset !important;
}