.ant-modal-body {
    background-color: #ebeeea;
    border-radius: 20px;
}

.ant-modal-content {
    background-color: transparent !important;
}

.Margin {
    margin-top: 20%;
}

.Reset {
    position: unset !important;
}

.ant-input:focus {
    box-shadow: none !important;
}

.ant-input {
    border: none !important;
    font-size: 21px !important;
}

.ant-input-affix-wrapper {
    border: none !important;
}

.ant-input-affix-wrapper:focus {
    box-shadow: none !important;
}

.ant-popover-inner-content {
    padding: 0px !important;
}

#GetAdvice {
    width: 100%;
    max-width: 450px !important;
    max-height: 100px !important;
}

#GetAdvice .ant-popover-inner-content>.dropdown-list-full-wrapper>.navigation-drop-container {
    height: 320px;
    overflow: auto;
}

.ant-popover-content {
    width: 100%;
    max-width: 600px;
}

.ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight{
    top: 118px !important;
}
.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow, .ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow, .ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
    box-shadow: none !important;
    border-top-color: transparent;
    border-left-color: transparent;
}

.ant-carousel .slick-dots {
    margin-right: 6% !important;
    /* justify-content: flex-end !important; */
    height: 75px;
    width: 150px;
    right: -7%;
    left: auto;
}

.ant-carousel .slick-dots li button {
    width: 15px;
    height: 15px;
    border-radius: 50% !important;
}

@media screen and (max-width: 767px) {
    .ant-carousel .slick-dots {
        width: 81px;
        right: -3%;
    }
}

.ant-form-item-explain, .ant-form-item-extra {
    padding: 2px !important;
}

.ant-input-affix-wrapper>input.ant-input {
    padding: 4px !important;
}

.RegisterPlaceHolder {
    font-size: 21px;
    color: #4b4276;
}

.ant-form {
    font-size: unset;
}

.cursor {
    cursor: pointer;
}

.textplaceholder {
    font-size: 21px;
    color: #4b4276;
    /* padding-top: 20px; */
    padding-left: 25px;
}